

export function capitalizeFirstLetter(txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
}

export function formatDate(date, ddmmyyyy = false, complete = false) {
    if (date === null) return date;

    if (typeof date === 'string') {
        date = new Date(date.length <= 12 ? date + 'T00:00:00' : date );
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan en 0
    const day = String(date.getDate()).padStart(2, '0');

    let extra = complete ? ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() : '';

    if (ddmmyyyy) {
        return `${day}/${month}/${year}${extra}`;
    } else {
        return `${year}-${month}-${day}${extra}`;
    }
}

export function moneyFormat(data) {
    return Number(data).toLocaleString('es-CL', { style: 'currency', currency: 'CLP', minimumFractionDigits: 0 });
}

export function addMinutesToDate(date, minutes, typeReturn = 'date') {

    if (typeof date === 'string') {
        date = new Date(date);
    }

    let newDate = new Date(date.getTime() + minutes * 60000);

    return typeReturn === 'string' ? formatDate(newDate) : newDate;

}
