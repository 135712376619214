/* eslint-disable no-unreachable */
import React, { useState } from "react";
import { Box as Bx } from "grommet";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { validateRUT } from "validar-rut";
import validator from "validator";
import Switch from "@mui/material/Switch";
import moment from "moment";
import "moment/locale/es";
import { api, apiV2 } from "../../services/api";
import Swal from "sweetalert2";
import { NavigateNext, ArrowBackIos } from '@material-ui/icons';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

const srow = {
  display: "flex",
  flexDirection: "row",
  marginTop: "10px",
};

const scolumn = {
  display: "flex",
  flexDirection: "column",
  marginRight: "2%",
};

const EventoPagando = ({ evento, venta, horario, entradas = [], origen, movil = false, handleCancelar, handlePagar }) => {

  const [loading, setLoading] = useState(false);
  const [termos, setTermos] = useState(false);
  const [nome, setNome] = useState("");
  const [apelido, setApelido] = useState("");
  const [email, setEmail] = useState("");
  const [emailC, setEmailC] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("rut");
  const [documento, setDocumento] = useState("");
  const [documentoValido, setDocValid] = useState(false);
  const [venda_id, setVendaId] = useState("");

  const TermosCon = () => {
    return (
      <Stack
        direction="row"
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <IOSSwitch sx={{ m: 1 }} onChange={() => setTermos(!termos)} />
        <p style={{ color: "white", fontSize: "0.8em" }}>
          Para procesar este pago, declaro que he revisado el DETALLE DE LA
          COMPRA, y que he leído y acepto los Términos y Condiciones, así como
          la Política de Protección de Datos Personales.{" "}
        </p>
      </Stack>
    );
  };

  const IOSSwitch = styled((props) => (
    <Switch
      checked={termos}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const validarDocumento = (e) => {
    if (e.target.value) {
      if (true
        /* validator.isPassportNumber(e.target.value, "IN") ||
        validateRUT(e.target.value) */
      ) {
        setDocValid(true);
      } else {
        alert("Erro Rut / Pasaporte invalido");
        setDocValid(false);
      }
    } else {
      setDocValid(false);
    }
  };

  async function handleNavigation(e) {
    e.preventDefault();
    handlePagar(1);
    if (!documentoValido) {
      handlePagar(0);
      alert("Documento inválido.");
    } else if (email !== emailC) {
      handlePagar(0);
      alert("Email de confirmación no coincide.");
    } else {

      if (parseInt(venta.valor) > 0) {
        let urlWeb = "https://webcinesol.busticket.cl/finalizado?";

        const dataTransbank = {
          valor_venta: venta.valor,
          valor_tbk: venta.valor + 600,
          url: urlWeb,
          venta_id: venta.id,
          metodo: 'plus',
          documento,
          email
        };

        apiV2.get("venta/congelar_venta/" + dataTransbank.venta_id).then(async (resp) => {
          webpayTransbank(dataTransbank)
        }).catch((error) => {
          alert("Error");
          handlePagar(0);
        })

      }
    }
  }

  async function webpayTransbank(dataTransbank) {
    apiV2.post("transbank/webpay", dataTransbank)
      .then(resp => {
        handlePagar(2, resp.data.url, resp.data.token)
      })
      .catch((error) => {
        console.log(error.response)
        alert("Error");
        handlePagar(0);
      });
  }


  if (loading) return <></>;
  return (
    <>
      {!movil ? (
        <div className="content-header">
          <Bx direction="row" align="center">
            <h4 style={{ color: "white", marginTop: '1rem' }}>
              Detalle de la compra
            </h4>
          </Bx>

          <Bx direction="row">
            <div>
              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Evento: <span style={{ fontWeight: 'normal' }}>{evento.titulo}</span>
                </h4>
              </div>
              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Fecha función: <span style={{ fontWeight: 'normal' }}>
                    {moment(horario.fecha).format("dddd DD/MM/YY")}{" "}
                    {horario.hora.substr(0, 5)}</span>
                </h4>
              </div>

              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Entradas:
                </h4>
                <p
                  style={{
                    fontFamily: "Poppins",
                    color: "white",
                  }}
                >
                  {entradas.map((item, index) => {
                    if (item.quantity !== 0)
                      return (
                        <>
                          {item.quantity + ` butaca(s) ${item.tipo}`}
                          <br />
                        </>
                      );
                  })}
                  <br />
                </p>
              </div>

            </div>

            <div className="description-first" style={{ marginLeft: 'auto', marginRight: '0px' }}>
              <Bx
                border={{
                  color: "#f26522",
                  size: "large",
                  style: "hidden",
                }}
                round="medium"
                background="#f26522"
                pad="medium"
                style={{ width: "12vw" }}
              >
                <h1
                  style={{
                    fontFamily: "Poppins",
                    color: "white",
                    textAlign: "center",
                    fontSize: "22px",
                  }}
                >
                  Total{" "}
                </h1>

                <h1
                  style={{
                    fontFamily: "Poppins",
                    color: "white",
                    textAlign: "center",
                    fontSize: "22px",
                  }}
                >
                  ${" "}
                  {Intl.NumberFormat("es-CL").format(
                    parseInt(venta.valor) + 600
                  )}
                </h1>
                <h6
                  style={{
                    fontFamily: "Poppins",
                    color: "white",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Incluye ${" "}
                  {parseInt(600).toLocaleString("pt-br", {
                    currency: "CLP",
                  })}{" "}
                  de cargo por servicio
                </h6>
              </Bx>
            </div>
          </Bx>
          <Bx direction="column" gap="8%">
            <h4 style={{ color: "white", fontFamily: 'Poppins' }} >Método de pago disponible{" "}</h4>
            <Bx height="small">
              <img
                src="https://www.transbankdevelopers.cl/public/library/img/svg/logo_webpay_plus.svg"
                alt=""
                style={{ width: "20%" }}
              />
              <hr size={2} style={{ marginTop: "1%" }} />
              <h4 style={{ color: "white", fontFamily: 'Poppins' }}>Acepta los términos y condiciones{" "}</h4>
              <TermosCon />

              <hr size={2} style={{ marginTop: "1%", marginBottom: "1%" }} />

              {termos ? (
                <>
                  <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
                    Completa tus datos para continuar{" "}
                  </h4>
                  <div>
                    <Box>
                      <Box sx={srow}>
                        <Box sx={scolumn}>
                          <input
                            required
                            id={"nome"}
                            key={"nome"}
                            value={nome}
                            placeholder="Nombre *"
                            onChange={(e) => setNome(e.target.value.toUpperCase())}
                          />
                        </Box>
                        <Box sx={scolumn}>
                          <input
                            required
                            key={"apelido"}
                            id={"apelido"}
                            placeholder="Apellido"
                            value={apelido}
                            onChange={(e) => setApelido(e.target.value.toUpperCase())}
                          />
                        </Box>
                      </Box>
                      <Box sx={srow}>
                        <Box sx={scolumn}>
                          <input
                            required
                            id={"email"}
                            type="email"
                            placeholder="Email *"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.toUpperCase())}
                          />
                        </Box>
                        <Box sx={scolumn}>
                          <input
                            required
                            id={"email_c"}
                            type="email"
                            placeholder="Email confirmación *"
                            value={emailC}
                            onChange={(e) => setEmailC(e.target.value.toUpperCase())}
                          />
                        </Box>
                      </Box>

                      <Box sx={srow}>
                        <Box sx={{ marginRight: '1.9%' }}>
                          <RadioGroup row value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)}>
                            <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Rut</Typography>} style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }} value="rut" control={<Radio style={{ color: 'white' }} />} />
                            <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Pasaporte</Typography>} value="pasaporte" control={<Radio style={{ color: 'white' }} />} />
                          </RadioGroup>
                        </Box>
                        <Box sx={scolumn}>
                          <input
                            required
                            key={"documento"}
                            id={"documento"}
                            placeholder="Documento *"
                            onBlur={validarDocumento}
                            value={documento}
                            onChange={(e) => setDocumento(e.target.value.toUpperCase())}
                          />
                        </Box>
                      </Box>

                    </Box>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div>
                <Button variant="contained" style={{
                  width: '150px',
                  height: '40px',
                  backgroundColor: '#CCCCCC',
                  color: '#333333',
                  border: '1px solid #CCCCCC',
                  margin: '20px 0 20px 2px',
                  cursor: 'pointer',
                  fontFamily: 'Poppins',
                  textTransform: 'capitalize'
                }} startIcon={<ArrowBackIos />} onClick={() => handleCancelar(origen)}>Volver</Button>
                {termos ? (
                  <Button variant="contained" style={{
                    width: '150px',
                    height: '40px',
                    backgroundColor: '#00C455',
                    border: '1px solid #00C455',
                    margin: '20px 0 20px 10px',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize'
                  }} endIcon={<NavigateNext />} onClick={handleNavigation}>Pagar</Button>
                ) : (
                  <> </>
                )}
              </div>
            </Bx>
          </Bx>
        </div>
      ) : (
        <Box sx={{ width: '85%', marginTop: '3%' }}>
          <h4 style={{ color: "white", fontFamily: "Poppins" }}>
            Detalle de la compra
          </h4>
          <div className="description-first">
            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
              Evento: <span style={{ fontWeight: 'normal' }}>{evento.titulo}</span>
            </h4>
          </div>
          <div className="description-first">
            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
              Fecha función: <span style={{ fontWeight: 'normal' }}>
                {moment(horario.fecha).format("dddd DD/MM/YY")}{" "}
                {horario.hora.substr(0, 5)}</span>
            </h4>
          </div>
          <div className="description-first">
            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
              Entradas:
            </h4>
            <p
              style={{
                fontFamily: "Poppins",
                color: "white",
              }}
            >
              {entradas.map((item, index) => {
                if (item.quantity !== 0)
                  return (
                    <>
                      {item.quantity +
                        ` butaca(s) ${item.tipo}`}
                      <br />
                    </>
                  );
              })}
            </p>
          </div>
          <div className="description-first">
            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
              Total: <span
                style={{ fontWeight: 'normal' }}>
                ${" "}{Intl.NumberFormat("es-CL").format(
                  parseInt(venta.valor) + 600
                )}
              </span>
              <span style={{ fontWeight: 'normal' }} >
                {" "}(Incluye ${" "}
                {parseInt(600).toLocaleString("pt-br", {
                  currency: "CLP",
                })}{" "}
                de cargo por servicio)
              </span>
            </h4>
          </div>
          <Bx direction="column" style={{ marginTop: "2%" }}>
            <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
              Método de pago disponible{" "}
            </h4>
            <Bx height="small">
              <img src="https://www.transbankdevelopers.cl/public/library/img/svg/logo_webpay_plus.svg"
                style={{ width: "50%" }} />
              <hr size={2} style={{ marginTop: "1%" }} />
              <h4 style={{ color: "white", fontFamily: "Poppins" }} >
                Acepta los Términos y condiciones{" "}
              </h4>
              <TermosCon />
              <hr size={2} style={{ marginTop: "1%" }} />
              {termos ? (
                <>
                  <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
                    Completa tus datos para continuar{" "}
                  </h4>
                  <input
                    style={{ marginTop: '2%' }}
                    required
                    id={"nome"}
                    key={"nome"}
                    value={nome}
                    placeholder="Nombre *"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                  />
                  <input
                    style={{ marginTop: '2%' }}
                    required
                    key={"apelido"}
                    id={"apelido"}
                    placeholder="Apellido"
                    value={apelido}
                    onChange={(e) => setApelido(e.target.value.toUpperCase())}
                  />
                  <input
                    style={{ marginTop: '2%' }}
                    required
                    id={"email"}
                    type="email"
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toUpperCase())}
                  />
                  <input
                    style={{ marginTop: '2%' }}
                    required
                    id={"email_c"}
                    type="email"
                    placeholder="Email confirmación *"
                    value={emailC}
                    onChange={(e) => setEmailC(e.target.value.toUpperCase())}
                  />
                  <RadioGroup row style={{ marginTop: '2%' }} value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)}>
                    <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Rut</Typography>} style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }} value="rut" control={<Radio style={{ color: 'white' }} />} />
                    <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Pasaporte</Typography>} value="pasaporte" control={<Radio style={{ color: 'white' }} />} />
                  </RadioGroup>
                  <input
                    style={{ marginTop: '2%' }}
                    required
                    key={"documento"}
                    id={"documento"}
                    placeholder="Documento *"
                    onBlur={validarDocumento}
                    value={documento}
                    onChange={(e) => setDocumento(e.target.value.toUpperCase())}
                  />
                  <hr size={2} style={{ marginTop: "4%", marginBottom: '2%' }} />
                </>

              ) : (
                <> </>
              )}
              <div className="footer-button" style={{ paddingBottom: '4%' }}>
                <Button variant="contained" style={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: '#CCCCCC',
                  color: '#333333',
                  border: '1px solid #CCCCCC',
                  marginTop: '2%',
                  cursor: 'pointer',
                  fontFamily: 'Poppins',
                  textTransform: 'capitalize'
                }} startIcon={<ArrowBackIos />} onClick={() => handleCancelar(origen)}>Volver</Button>
                {termos ? (
                  <Button variant="contained" style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#00C455',
                    border: '1px solid #00C455',
                    marginTop: '2%',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize'
                  }} endIcon={<NavigateNext />} onClick={handleNavigation}>Pagar</Button>
                ) : (
                  <> </>
                )}
              </div>
            </Bx>
          </Bx>
        </Box>
      )}
    </>

  );
};

export default EventoPagando;
