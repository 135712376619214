import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stepper from "../../components/Stepper";
import { NavigateNext } from '@material-ui/icons';
import { Box as Bx, Layer, Spinner, Text } from "grommet";

import background from "../../assets/background.png";

import { BrowserView, MobileView } from "react-device-detect";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";

import { api, apiV2 } from "../../services/api";
import moment from "moment";
import { EventoAssent } from "../../components/eventos/EventoAssento";
import EventoPagando from "../../components/eventos/EventoPagando";
import { capitalizeFirstLetter } from "../../services/commonServices";

export const Evento = () => {

  const { eventoId } = useParams();
  const [evento, setEvento] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [datas, setDatas] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [horarios, setHorarios] = useState([]);
  const [total, setTotal] = useState(0);
  const [horario, setHorario] = useState(null);
  const [entradas, setEntradas] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [sale, setSale] = useState(null);


  const [selectedInfo, setSelcted] = React.useState(null);
  const [paying, setPaying] = React.useState(false);
  const [selectSeat, setSelectSeat] = React.useState(false);
  const [opcionNav, setOpcionNav] = React.useState(0);
  const [pasaAssent, setPasaAssent] = React.useState(false);


  const [loadingPay, setLP] = useState(false);
  const [url_wp1, setUrl_wp1] = useState("");
  const [token_wp1, setToken_wp1] = useState("");

  const styleboxfilme = {
    display: "flex",
    marginTop: '4%',
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`,
    height: "110%",
  };

  useEffect(() => {
    getEvent();
  }, []);

  async function getEvent() {
    apiV2.get("/evento/" + eventoId).then(async resp => {
      await setEvento(resp.data.evento);
      await parseDates(resp.data.fechas)
      setLoading(false);
    })
  }

  function parseDates(dates) {
    let dateParse

    const optionDay = { weekday: 'short' };
    const optionMonth = { month: 'short' };

    const newDates = dates.map(date => {
      dateParse = new Date(date + 'T00:00:00');
      return {
        complete: date,
        dayTxt: capitalizeFirstLetter(dateParse.toLocaleDateString('es-ES', optionDay)),
        dayInt: dateParse.getDate(),
        monthTxt: capitalizeFirstLetter(dateParse.toLocaleDateString('es-ES', optionMonth)),
        monthInt: ('0' + (dateParse.getMonth() + 1)).slice(-2)
      }
    })
    setDatas(newDates);
  }

  const handleSelectedDate = async (selected) => {
    if (selectedDate?.complete !== selected.complete) {
      setSelectedDate(selected);
      await limpiar();
      getHorarios(selected.complete);
    }
  };

  async function getHorarios(date) {
    const schedules = await evento.horarios.filter(schedule => schedule.fecha === date)
    setHorarios(schedules);
  }

  function limpiar() {
    setHorarios([]);
    setHorario(null);
    setEntradas([]);
    setTotal(0);
    setQuantity(0);
  }

  const handleSelectedHorario = async (selected) => {
    if (horario?.id !== selected.id) {
      let entradas = []
      await selected.precios.map(e => {
        entradas.push({
          id: e.id,
          tipo: e.tipo.nombre,
          valor: e.precio,
          total: 0,
          quantity: 0
        })
      })
      setEntradas(entradas);
      setHorario(selected);
      setTotal(0);
      setQuantity(0);
    }
  }

  const handleNumbTicket = async (key, value) => {
    let entries = [...entradas]
    entries[key].quantity = value;
    calculateTotalEvent(entries)
  }

  const calculateTotalEvent = (entries) => {

    let totall = 0;
    let value = 0;
    let quantity = 0;

    entries.map(e => {
      value = e.quantity * e.valor;
      e.total = value;
      quantity += e.quantity;
      totall += value;
    })

    setEntradas(entries);
    setTotal(totall)
    setQuantity(quantity)
  }

  async function continuar() {
    if (horario?.tipo_entrada === 2) { // 1 cantidad, 2 asientos
      setPasaAssent(true);
      setSelectSeat(true);
      setOpcionNav(1);
    } else {
        const booking = {
          canal_id: 2, // web
          asientos: [],
          entries: entradas,
          cantidad_i: 0,
          cantidad_m: 0,
          cantidad: quantity,
          horario_id: horario.id,
          valor_total: total
        }

        apiV2.post('venta/reservar-evento', booking).then(resp => {
          setSale(resp.data.venta);
          setPaying(true);
          setOpcionNav(2);
        })
    }
  }

  const TabOption = () => {
    if (selectSeat === false && paying === false) {
      return (
        <>

          <div className="calendario-direitaaa">
            <div className="conainer-selecione-date">
              <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione fecha</h4>
              <div className="container-DATAS">
                {datas.map(item => {
                  return (
                    <button
                      className={
                        selectedDate?.complete === item.complete
                          ? "content-selecione-date-date-Dom-active"
                          : "content-selecione-date-date-Dom"
                      }
                      onClick={() => handleSelectedDate(item)}
                    >
                      <div className="main-content-date-date-Dom">
                        <p
                          className={
                            selectedDate?.complete === item.complete
                              ? "first-p-active"
                              : "first-p"
                          }
                        >
                          {item.dayTxt}
                        </p>
                        <p
                          className={
                            selectedDate?.complete === item.complete
                              ? "last-p-active"
                              : "last-p"
                          }
                        >
                          {item.dayInt}
                        </p>
                        <p
                          className={
                            selectedDate?.complete === item.complete
                              ? "tri-p-active"
                              : "tri-p"
                          }
                        >
                          {item.monthTxt}
                        </p>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
            <Bx margin={{ left: "2.5%" }}>
              {selectedDate !== null && (
                <>
                  <div className="container-seccion-carteira">
                    <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione la función</h4>

                    <div>
                      {horarios.map((item) => {
                        return (
                          <button
                            className={
                              horario?.id === item.id
                                ? "container-secction-button-selected"
                                : "container-secction-button"
                            }
                            style={{ border: '0px' }}
                            onClick={() => {
                              handleSelectedHorario(item);
                            }}
                          >
                            <p
                              className={
                                horario?.id === item.id
                                  ? "button-first-txt-selected"
                                  : "button-first-txt"
                              }
                            >
                              {item.sala.nombre}
                            </p>
                            <p
                              className={
                                horario?.id === item.id
                                  ? "button-last-txt-selected"
                                  : "button-last-txt"
                              }
                            >
                              {item.hora.substring(0, 5)}
                            </p>
                          </button>
                        );
                      })}
                    </div>
                  </div>

                  {horario !== null && (
                    <>
                      <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Cantidad</h4>

                      <Box gap="small" style={{ marginTop: 5 }}>
                        {entradas.map((item, index) => {
                          return (
                            <div className="container-main-igresso-column01" style={{ marginLeft: "10px", marginTop: '4px' }}>
                              <ButtonGroup>
                                <Button style={{ backgroundColor: "white", color: "black" }} onClick={() => handleNumbTicket(index, item.quantity - 1)} >
                                  -
                                </Button>
                                <Button disable="true" variant="text" style={{ color: "white" }}>
                                  {item.quantity}
                                </Button>
                                <Button style={{ backgroundColor: "white", color: "black" }} onClick={() => handleNumbTicket(index, item.quantity + 1)} >
                                  +
                                </Button>
                              </ButtonGroup>
                              <p
                                style={{ color: "white", fontFamily: "Poppins" }}
                                className="description-igresso"
                              >
                                {item.tipo}{" ("}{item.quantity}
                                {"x) $ " + item.total.toLocaleString('es-CL') ?? 0}
                              </p>
                            </div>
                          );
                        }
                        )}
                      </Box>
                      <br />

                      <hr style={{ width: "75%", marginBottom: "2%" }} />
                      <Button variant="contained" style={{
                        width: '150px',
                        height: '40px',
                        backgroundColor: quantity === 0 ? '#c3c3c3' : '#00C455',
                        border: quantity === 0 ? '1px solid #c3c3c3' : '1px solid #00C455',
                        margin: '20px 0 20px 10px',
                        cursor: 'pointer',
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize'
                      }} endIcon={<NavigateNext />}
                        disabled={quantity === 0}
                        onClick={() => continuar()}>Continuar</Button>
                    </>
                  )}
                </>
              )}
            </Bx>
          </div>
        </>
      );
    } else if (selectSeat === true && paying === false) {
      return (
        <>
          <EventoAssent
            assentos={selectedInfo.servico.assentos}
            key={eventoId}
            setSelected={setSelcted}
            selectedInfo={selectedInfo}
            nextStep={() => {
              setSelectSeat(false);
              setPaying(true);
              setOpcionNav(2);
            }}
            prevStep={() => {
              setSelectSeat(false);
              setOpcionNav(0);
              setPasaAssent(false);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <EventoPagando
            key={eventoId}
            evento={evento}
            venta={sale}
            horario={horario}
            entradas={entradas}
            eventoId={eventoId}
            origen={pasaAssent ? 1 : 0}
            handleCancelar={(origen) => {
              setOpcionNav(origen);
              setPaying(false);
              if (origen === 1) {
                setSelectSeat(true);
              }
            }}
            handlePagar={(pagando, url_wp1 = null, token_wp1 = null) => {
              setLP(pagando !== 0);
              if (pagando === 2) {
                setUrl_wp1(url_wp1)
                setToken_wp1(token_wp1)
              }
            }}
          />
        </>
      );
    }
  }

  const TabOptionMovil = () => {
    if (selectSeat === false && paying === false) {
      return (
        <Box sx={{ width: "85%" }}>
          <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
            Seleccione fecha
          </h4>
          <div className="container-DATAS">
            {datas.map((item) => {
              return (
                <button
                  className={
                    selectedDate?.complete === item.complete
                      ? "content-selecione-date-date-Dom-active"
                      : "content-selecione-date-date-Dom"
                  }
                  onClick={() => handleSelectedDate(item)}
                >
                  <div className="main-content-date-date-Dom">
                    <p
                      className={
                        selectedDate?.complete === item.complete
                          ? "first-p-active"
                          : "first-p"
                      }
                    >
                      {item.dayTxt}
                    </p>
                    <p
                      className={
                        selectedDate?.complete === item.complete
                          ? "last-p-active"
                          : "last-p"
                      }
                    >
                      {item.dayInt}
                    </p>
                    <p
                      className={
                        selectedDate?.complete === item.complete
                          ? "tri-p-active"
                          : "tri-p"
                      }
                    >
                      {item.monthTxt}
                    </p>
                  </div>
                </button>
              );
            })}
          </div>
          {selectedDate && (
            <>
              <div className="container-seccion-carteira">
                <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                  Seleccione la función
                </h4>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 0 10px 0",
                    maxWidth: "100%",
                  }}
                >
                  {horarios.map((item) => {
                    return (
                      <button
                        className={
                          horario?.id === item.id
                            ? "container-secction-button-selected"
                            : "container-secction-button"
                        }
                        style={{ border: '0px' }}
                        onClick={() => handleSelectedHorario(item)}
                      >
                        <p
                          className={
                            horario?.id === item.id
                              ? "button-first-txt-selected"
                              : "button-first-txt"
                          }
                        >
                          {item.sala.nombre}
                        </p>
                        <p
                          className={
                            horario?.id === item.id
                              ? "button-last-txt-selected"
                              : "button-last-txt"
                          }
                        >
                          {item.hora.substring(0, 5)}
                        </p>
                      </button>
                    );
                  })}
                </div>
              </div>

              {horario !== null && (
                <>
                  <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                    Cantidad
                  </h4>
                  <Box gap="small" style={{ marginTop: '2%' }}>
                    {entradas.map(
                      (item, index) => {
                        return (
                          <div className="container-main-igresso-column01" style={{ marginLeft: '10px', marginBottom: '2%' }}>
                            <ButtonGroup>
                              <Button style={{ backgroundColor: "white", color: "black" }}
                                onClick={() => { handleNumbTicket(index, item.quantity - 1) }} >
                                -
                              </Button>
                              <Button disable="true" variant="text" style={{ color: "white" }}>
                                {item.quantity}
                              </Button>
                              <Button style={{ backgroundColor: "white", color: "black" }} onClick={() => handleNumbTicket(index, item.quantity + 1)}>
                                +
                              </Button>
                            </ButtonGroup>
                            <p style={{ color: "white", fontFamily: "Poppins" }}>
                              {item.tipo}{" ("}{item.quantity}
                              {"x) $ " + item.total.toLocaleString('es-CL') ?? 0}
                            </p>
                          </div>
                        );
                      }
                    )}
                  </Box>
                  <hr style={{ width: "100%", marginBottom: "2%", marginLeft: '10px' }} />

                  <div className="footer-button" style={{ marginLeft: '10px', paddingBottom: "4%" }}>
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '40px',
                      marginTop: '2%',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize',
                      backgroundColor: quantity === 0 ? '#c3c3c3' : '#00C455',
                      border: quantity === 0 ? '1px solid #c3c3c3' : '1px solid #00C455'
                    }} endIcon={<NavigateNext />}
                      disabled={quantity === 0}
                      onClick={() => continuar()}>Continuar</Button>
                  </div>
                </>
              )}
            </>
          )}

        </Box>
      );
    } else if (selectSeat === true && paying === false) {
      return (
        <>
          <EventoAssent
            assentos={selectedInfo.servico.assentos}
            key={eventoId}
            setSelected={setSelcted}
            selectedInfo={selectedInfo}
            movil={true}
            nextStep={() => {
              setSelectSeat(false);
              setPaying(true);
              setOpcionNav(2);
            }}
            prevStep={() => {
              setSelectSeat(false);
              setOpcionNav(0);
              setPasaAssent(false);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <EventoPagando
            key={eventoId}
            evento={evento}
            venta={sale}
            horario={horario}
            entradas={entradas}
            eventoId={eventoId}
            origen={pasaAssent ? 1 : 0}
            movil={true}
            handleCancelar={(origen) => {
              setOpcionNav(origen);
              setPaying(false);
              if (origen === 1) {
                setSelectSeat(true);
              }
            }}
            handlePagar={(pagando, url_wp1 = null, token_wp1 = null) => {
              setLP(pagando !== 0);
              if (pagando === 2) {
                setUrl_wp1(url_wp1)
                setToken_wp1(token_wp1)
              }
            }}
          />
        </>
      );
    }
  }

  React.useEffect(() => {
    if (url_wp1 && token_wp1) document.formulario.submit();
  }, [url_wp1, token_wp1]);

  if (loading) return <></>;
  return (
    <>
      {loadingPay && (
        <Layer responsive={false} pad="large" background={"transparent"}>
          <Bx
            align="center"
            justify="center"
            pad={"xlarge"}
            background="white"
            style={{ borderRadius: "15px" }}
            gap="large"
          >
            <Text style={{ maxWidth: "400px", textAlign: "center" }}>
              Aguarde, estamos redirecionando a Transbank, favor no actualice la
              página .
            </Text>
            <Spinner size="large" />
          </Bx>
        </Layer>
      )}
      <form method="post" action={url_wp1} name="formulario">
        <input type="hidden" name="token_ws" value={token_wp1} />
      </form>
      <BrowserView>
        <div className="container-capa" style={{ backgroundImage: `url(${background})` }}>
          <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
            <div>
              <div className="container-pai">
                <div className="container-pai-filme">
                  {opcionNav === 0 && (
                    <div className="detalhes-filme">
                      <img
                        src={evento?.link_img}
                        alt=""
                        className="img-capa"
                      />
                      <div className="infos-filme-carteira">
                        <h2 className="title-sinopse">{evento?.titulo}</h2>
                        <p style={{ color: "black" }}>2019 - Ação | Fantasia</p>
                        {/* <p>Diretor: {.diretor}</p> */}
                        <p>
                          {" "}
                          <AiFillStar className="iconStar" />
                          7.5 (85,741)
                        </p>
                        <h6>
                          We all have a superhero inside us, it just takes a bit
                          of magic to bring it out. In Billy Batson's case, by
                          shouting out one word - SHAZAM. - this streetwise
                          fourteen-year-old foster kid can turn into the grown-up
                          superhero Shazam
                        </h6>
                      </div>
                    </div>
                  )}
                  <div className="informacoes-detalhes">
                    <Stepper active={opcionNav} />
                    <TabOption />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div >
      </BrowserView >

      <MobileView>

        <Box sx={styleboxfilme}>
          <Stepper active={opcionNav} />
          {opcionNav === 0 ? (
            <div className="detalhes-filme movile">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={evento?.link_img}
                  style={{ marginTop: "4vh" }}
                />
                <div>
                  <h2 className="title-sinopse" style={{ color: "white" }}>
                    {evento?.titulo}
                  </h2>
                </div>
              </Box>
            </div>
          ) : (
            <></>
          )}
          <TabOptionMovil />
        </Box>
      </MobileView>
    </>
  );
};
