import { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Butacas.styles.css";
import userIcon from "../../assets/user-icon.png";
import { api, apiV2 } from "../../services/api";
import Box from "@mui/material/Box";
import background from "../../assets/background.png";
import {
  LoginModalTelefone,
} from "../../screens/SignIn/indexAsModalPc";
import { ButtonWhatsapp } from "../../components/ButtonWhatsapp/index";
import { BrowserView, MobileView } from "react-device-detect";
import Stepper from "../../components/Stepper";

import { HeaderT, FooterT } from "../../components/HomeCompo/HomeCompo";
import { Sidebar } from "primereact/sidebar";
import Button from "@mui/material/Button";
import { NavigateNext, ArrowBackIos } from '@material-ui/icons';

import { TimerGlobal } from "../../components/Timer/timer";
export const Butacas = () => {
  const [visibleFullScreenCelular, setVisibleFullScreenCelular] =
    useState(false);
  const [bloques, setBloques] = useState([]);
  const [ingressosSelecionados, setIngressosSelecionados] = useState([]);

  const [seatIds, setSeatIds] = useState([]);
  const [seatsNames, setSeatsNames] = useState([]);
  const [reservedId, setReservedId] = useState([]);
  const history = useHistory();

  const location = useLocation();
  const { request } = location.state || {};

  const styleboxfilme = {
    paddingTop: '4%',
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`,
    height: "110%",
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    console.log(request)
    if (request) {
      getAssentos();
    } else {
      cancelarCompra()
    }
  }

  async function getAssentos() {
    apiV2.get('sala/asientos/' + request.schedule.id).then(resp => {
      setReservedId(resp.data.ocupados);
      setBloques(resp.data.bloques);
      setSeatIds(request.seatIds);
      setSeatsNames(request.seatsNames);
    })
  }

  const selectSeat = (seatId, name) => {

    let ids = [...seatIds];
    if (ids.includes(seatId)) {
      ids = ids.filter(e => e !== seatId);
    } else {
      ids.push(seatId)
    }

    let names = [...seatsNames];
    if (names.includes(name)) {
      names = names.filter(n => n !== name);
    } else {
      names.push(name)
    }

    setSeatIds(ids);
    setSeatsNames(names)

  }

  const reservar = async () => {
    const booking = {
      canal_id: 2, // web
      asientos: seatIds,
      cantidad_i: request.quantityI,
      cantidad_m: request.quantityM,
      horario_id: request.schedule.id,
      cupon: request.cupon,
      valor_total: request.total
    }

    apiV2.post('venta/reservar-asientos', booking).then(resp => {
      request.seatIds = seatIds;
      request.seatsNames = seatsNames;
      request.sale = resp.data.venta;
      history.push("/pago?id=" + request.schedule.pelicula_id, { request });
    })

  };

  const cancelarCompra = function () {
    history.push("/pelicula?id=" + request.schedule.pelicula_id);
  };


  const renderBlock = (block) => {

    const items = [];
    let seats = [];
    const seatsTemp = block.asientos;

    for (let y = 0; y < block.y; y++) {

      for (let x = 0; x < block.x; x++) {

        let seatTemp = seatsTemp.find(e => e.x === x && e.y === y);

        seatTemp.selected = seatIds.includes(seatTemp.id)
        seatTemp.ocupado = reservedId.includes(seatTemp.id)

        seats.push(
          <Fragment key={seatTemp.id}>
            {seatTemp.ignorar === 1 ? ( // asiento ocultado en la sala
              <button className={"assento " + (bloques.length <= 2 ? "x15" : "")}
                style={{ opacity: 0, cursor: "default" }}> </button>
            ) : seatTemp.selected ? ( // asiento seleccionado
              <button className={"assento selecionado " + (bloques.length <= 2 ? "x15" : "")}
                onClick={() => selectSeat(seatTemp.id, seatTemp.nombre)} >
                {seatTemp.nombre}
              </button>
            ) : seatTemp.ocupado ? (
              <button className={"assento bloqueado " + (bloques.length <= 2 ? "x15" : "")} disabled={true} >
                <img src={userIcon} alt="use" />
              </button>
            ) : (
              <button className={"assento " + (bloques.length <= 2 ? "x15" : "")} disabled={seatIds.length === request.quantity}
                onClick={() => selectSeat(seatTemp.id, seatTemp.nombre)}>
                {seatTemp.nombre}
              </button>
            )}
          </ Fragment>
        )
      }

      items.push(
        <div className="linha" key={y}>
          {seats}
        </div>
      )
      seats = [];
    }

    return items;

  }


  return (
    <>
      <BrowserView>
        <ButtonWhatsapp />
        <Box sx={{ backgroundImage: `url(${background})` }}>
          <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
            <div>
              <div style={{ flexWrap: "wrap" }}>
                <div className="container-pai">
                  <div className="container-pai-filme">
                    <div className="informacoes-detalhes">
                      <Stepper active={1} />
                      <div className="content-payment">
                        <div className="infos-compra">
                          <h4 className="title-selecione-header">
                            Selección de butacas: {ingressosSelecionados.length}{" "}
                            de {parseInt(request.quantity)}{" "}
                          </h4>
                          <h4 className="title-selecione-header">
                            {" "}
                            <TimerGlobal start={true} />{" "}
                          </h4>
                        </div>
                        <p
                          className="subtitle-header"
                          style={{ color: "white" }}
                        >
                          Pantalla
                        </p>
                        <div className="pantalla"></div>
                        <div className="sala">

                          {bloques.map(block => (
                            <div className='bloco' key={block.id}>
                              {renderBlock(block)}
                            </div>
                          ))}

                        </div>

                        <div
                          className="flex-colunm"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="display-flex">
                            <div className="assento bloqueado">
                              <img src={userIcon} alt="user icon ocupado" />
                            </div>
                            <span style={{ color: "white", fontSize: '14px' }}>Ocupado</span>
                          </div>
                          <div className="display-flex">
                            <div className="assento selecionado"></div>
                            <span style={{ color: "white", fontSize: '14px' }}>Seleccionado</span>
                          </div>
                        </div>

                        <br />
                        <Button variant="contained" style={{
                          width: '150px',
                          height: '40px',
                          backgroundColor: '#CCCCCC',
                          color: '#333333',
                          border: '1px solid #CCCCCC',
                          margin: '0px 0 20px 10px',
                          cursor: 'pointer',
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize'
                        }} startIcon={<ArrowBackIos />} onClick={cancelarCompra}>Volver</Button>
                        {seatIds.length ===
                          parseInt(request.quantity) ? (
                          <Button variant="contained" style={{
                            width: '150px',
                            height: '40px',
                            backgroundColor: '#00C455',
                            border: '1px solid #00C455',
                            margin: '0px 0 20px 10px',
                            cursor: 'pointer',
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize'
                          }} endIcon={<NavigateNext />} onClick={reservar}>Pagar</Button>
                        ) : (
                          <></>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </BrowserView>

      <MobileView>
        <ButtonWhatsapp />
        {visibleFullScreenCelular === true ? (
          <Sidebar
            style={{ backgroundImage: `url(${background})`, height: "100%" }}
            visible={visibleFullScreenCelular}
            fullScreen
            onHide={() => setVisibleFullScreenCelular(false)}
          >
            <LoginModalTelefone
              turnModal={() => setVisibleFullScreenCelular(false)}
            />
          </Sidebar>
        ) : (
          <>
            <Box sx={styleboxfilme}>
              <Stepper active={1} />
              <div style={{ marginTop: '4%' }}>
                <h4 style={{ color: "white", fontFamily: "Poppins" }}>
                  Selección de butacas: {ingressosSelecionados.length} de{" "}
                  {parseInt(request.quantity)}
                </h4>
              </div>
              <p className="subtitle-header" style={{ color: "white" }}>Pantalla</p>
              <hr
                style={{
                  width: "90%",
                  border: "6px solid var(--color-dark-grreen)",
                  borderRadius: "10px",
                }}
              />

              <div className="view-butacas">
                <div className="sala" style={{ marginLeft: "2%" }}>
                  {bloques.map(block => (
                    <div className='bloco' key={block.id}>
                      {renderBlock(block)}
                    </div>
                  ))}
                </div>
              </div>
              <Box>
                <div className="flex-colunm">
                  <div className="display-flex">
                    <div className="assento bloqueado">
                      <img src={userIcon} alt="user icon ocupado" />
                    </div>
                    <span style={{ color: "white" }}>Ocupado</span>
                  </div>
                  <div className="display-flex" style={{ marginLeft: "4%" }}>
                    <div className="assento selecionado"></div>
                    <span style={{ color: "white" }}>Seleccionado</span>
                  </div>
                </div>
                <div className="footer-button" style={{ marginLeft: '10px', paddingBottom: "4%" }}>
                  <Button variant="contained" style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#CCCCCC',
                    color: '#333333',
                    border: '1px solid #CCCCCC',
                    marginTop: '2%',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize'
                  }} startIcon={<ArrowBackIos />} onClick={cancelarCompra}>Volver</Button>
                  {seatIds.length ===
                    parseInt(request.quantity) ? (
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: '#00C455',
                      border: '1px solid #00C455',
                      marginTop: '2%',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize'
                    }} endIcon={<NavigateNext />} onClick={reservar}>Pagar</Button>
                  ) : (
                    <></>
                  )}
                </div>
              </Box>

            </Box>
          </>
        )}
      </MobileView>
    </>
  );
};
