import React, { useState, useEffect } from "react";
import { ButtonSeg } from "../../components/ButtonDates/ButtonSeg";
import { ButtonQua } from "../../components/ButtonDates/ButtonQuad";
import { ButtonQui } from "../../components/ButtonDates/ButtonQui";
import { ButtonSex } from "../../components/ButtonDates/ButtonSex";
import { ButtonSab } from "../../components/ButtonDates/ButtonSab";
import { ButtonDom } from "../../components/ButtonDates/ButtonDom";
import { ButtonTer } from "../../components/ButtonDates/ButtonTer";
import { User } from "../../components/User";
import { useHistory } from "react-router-dom";
import "./Wallet.styles.css";
import { AiFillStar } from "react-icons/ai";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { NavigateNext } from '@material-ui/icons';
import Swal from "sweetalert2";

import background from "../../assets/background.png";

import { BrowserView, MobileView } from "react-device-detect";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { Box as Bx, Text } from "grommet";
import { ButtonWhatsapp } from "../../components/ButtonWhatsapp/index";

import { api, apiV2 } from "../../services/api";
import Stepper from "../../components/Stepper";
import { capitalizeFirstLetter } from "../../services/commonServices";
export const Wallet = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 14,
      fontFamily: ["Poppins"].join(","),
      width: "auto",
      padding: "6px 8px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      "::placeholder": {
        fontSize: 14,
        fontFamily: ["Poppins"].join(","),
      },
    },
  }));

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "6px 8px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });
  const [filme, setFilme] = useState({});
  const [datas, setDatas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [qtdInteiras, setQtdInteiras] = useState(1);
  const [qtdMeias, setQtdMeias] = useState(0);
  const [cupom, setCupom] = useState();
  const [cupomInfo, setCupomInfo] = useState(null);
  const [total, setTotal] = useState(0);

  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedHorario, setSelectedHorario] = useState(false);

  const history = useHistory();
  const styleboxfilme = {
    display: "flex",
    paddingTop: '4%',
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`
  };


  async function getFilme() {
    let params = new URLSearchParams(window.location.search);

    apiV2.get("/pelicula/" + params.get("id")).then(resp => {
      setFilme(resp.data.pelicula);
      parseDates(resp.data.fechas)
    })

  }

  function parseDates(dates) {
    let dateParse

    const optionDay = { weekday: 'short' };
    const optionMonth = { month: 'short' };

    const newDates = dates.map(date => {
      dateParse = new Date(date + 'T00:00:00');
      return {
        complete: date,
        dayTxt: capitalizeFirstLetter(dateParse.toLocaleDateString('es-ES', optionDay)),
        dayInt: dateParse.getDate(),
        monthTxt: capitalizeFirstLetter(dateParse.toLocaleDateString('es-ES', optionMonth)),
        monthInt: ('0' + (dateParse.getMonth() + 1)).slice(-2)
      }
    })
    setDatas(newDates);
  }

  useEffect(() => {
    getFilme();
  }, []);

  //funcao Navigation
  const handleNavigation = () => {

    if (qtdInteiras == 0) {
      Swal.fire(
        "Falta información!",
        "Favor digite la cantidad de entradas.",
        "error"
      )
    } else {
      let request = {
        filme,
        selectedDate,
        date: selectedHorario.fecha,
        schedule: selectedHorario,
        sale: null,
        quantityI: qtdInteiras,
        quantityM: qtdMeias,
        quantity: qtdInteiras + qtdMeias,
        cupon: cupomInfo,
        totalI: total,
        totalM: 0,
        total: total,
        seatIds: [],
        seatsNames: [],
        mathodsPay: []
      }

      history.push("/butacas?id=" + filme.id, { request });
    }

  };

  const handleCupomInfos = (e) => {
    setCupom(e.target.value);
  };

  const inserirCupom = async () => {

    if (filme.estreno == 1 || cupomInfo !== null) return;

    let data = {
      codigo: cupom,
      horarioId: selectedHorario.id,
      esApp: 0
    }

    apiV2.post('/cupon/validar', data).then(resp => {
      setCupomInfo(resp.data.cupon);
      Swal.fire({
        title: 'Cupón aceptado!',
        text: resp.data.cupon.promocion.tipo_promocion === 1 ? 'Descuento aplicado' : `Tienes hasta ${resp.data.cupon.saldo} entrada(s) con este cupón`,
        icon: 'success',
        confirmButtonColor: '#00c455'
      })

      handleValuesTotal(qtdInteiras, resp.data.cupon);

    }).catch(error => {
      console.log(error.response?.data?.msj ?? '')
      setCupom('')
      Swal.fire(
        "Código inválido!",
        error.response?.data?.msj ?? '',
        "error"
      );
    })

  };

  const handleValuesTotal = (qtdI, cupon) => {

    let value = selectedHorario.valor;
    let totalI = qtdI * value;
    let totalM = (qtdMeias / 2) * value;
    let totalTemp = totalI + totalM;

    let discountValue = 0;
    let discountQuantity = 0;

    if (cupon) {
      switch (cupon.promocion.tipo_descuento_id) {
        case 1: // Porcentaje
          discountValue = totalTemp * (cupon.promocion.descuento / 100);
          break;
        case 2: // Valor
          discountValue = (cupon.promocion.descuento > totalTemp ? totalTemp : cupon.promocion.descuento);
          break;
        case 3: // Cantidad

          let quantityDiscountI = cupon.saldo > qtdInteiras ? qtdInteiras : cupon.saldo;
          // descuento a entradas generales
          let discountValueI = value * quantityDiscountI;

          // descuento a entradas medias
          let remainingBalance = cupon.saldo - quantityDiscountI
          let quantityDiscountM = remainingBalance === 0 ? 0 : remainingBalance > qtdMeias ? qtdMeias : remainingBalance;
          let discountValueM = (value / 2) * quantityDiscountM;

          discountValue = parseInt(discountValueI + discountValueM);
          discountQuantity = quantityDiscountI + quantityDiscountM;
          break;
      }
      totalTemp -= discountValue;
      cupon['valor_descuento'] = discountValue;
      cupon['cantidad_descuento'] = discountQuantity;
    }

    setTotal(totalTemp);

    setCupomInfo(cupon);

  };

  const handleSelectedDate = (selected) => {
    setSelectedDate(selected);
    setSelectedHorario(false);
    setQtdMeias(0);
    setQtdInteiras(0);
    setTotal(0);
    loadSchedules(selected.complete);
  };

  const loadSchedules = async (date) => {
    setHorarios([]);
    const schedules = await filme.horarios.filter(schedule => schedule.fecha === date)
    setHorarios(schedules);
  }

  const handleSelectedHorario = (selected) => {
    setSelectedHorario(selected);
    setQtdMeias(0);
    setQtdInteiras(1);
    setTotal(selected.valor)
    setCupomInfo(null);
  };

  return (
    <>
      <BrowserView>
        <ButtonWhatsapp />
        <div
          className="container-capa"
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
            {/* <HeaderCar /> */}
            <div>
              <div className="container-pai">
                <div className="container-pai-filme">
                  <div className="detalhes-filme">
                    <img
                      src={filme.link_img}
                      alt=""
                      className="img-capa"
                    />
                    <div className="infos-filme-carteira">
                      <h2 className="title-sinopse">{filme.titulo}</h2>
                      <p style={{ color: "black" }}>2019 - Ação | Fantasia</p>
                      <p>Diretor: {filme.director}</p>
                      <p>
                        {" "}
                        <AiFillStar className="iconStar" />
                        7.5 (85,741)
                      </p>
                      <h6>
                        We all have a superhero inside us, it just takes a bit
                        of magic to bring it out. In Billy Batson's case, by
                        shouting out one word - SHAZAM. - this streetwise
                        fourteen-year-old foster kid can turn into the grown-up
                        superhero Shazam
                      </h6>
                    </div>
                  </div>

                  <div className="informacoes-detalhes">
                    <Stepper active={0} />
                    <div className="calendario-direitaaa">
                      <div className="conainer-selecione-date">
                        <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione fecha</h4>
                        <div className="container-DATAS">
                          {datas.map((item) => {
                            return (
                              <button
                                className={
                                  selectedDate.complete == item.complete
                                    ? "content-selecione-date-date-Dom-active"
                                    : "content-selecione-date-date-Dom"
                                }
                                onClick={() => handleSelectedDate(item)}
                              >
                                <div className="main-content-date-date-Dom">
                                  <p
                                    className={
                                      selectedDate.complete == item.complete
                                        ? "first-p-active"
                                        : "first-p"
                                    }
                                  >
                                    {item.dayTxt}
                                  </p>
                                  <p
                                    className={
                                      selectedDate.complete == item.complete
                                        ? "last-p-active"
                                        : "last-p"
                                    }
                                  >
                                    {item.dayInt}
                                  </p>
                                  <p
                                    className={
                                      selectedDate.complete == item.complete
                                        ? "tri-p-active"
                                        : "tri-p"
                                    }
                                  >
                                    {item.monthTxt}
                                  </p>
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      <Bx margin={{ left: "2.2%" }}>
                        {selectedDate && (
                          <>
                            <div className="container-seccion-carteira">
                              <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione la función</h4>

                              <div>
                                {/** Final Button Seccicion 01*/}
                                {horarios.map((item) => {
                                  return (
                                    <button
                                      className={
                                        selectedHorario.id == item.id
                                          ? "container-secction-button-selected"
                                          : "container-secction-button"
                                      }
                                      style={{ border: '0px' }}
                                      onClick={() =>
                                        handleSelectedHorario(item)
                                      }
                                    >
                                      <p
                                        className={
                                          selectedHorario.id == item.id
                                            ? "button-first-txt-selected"
                                            : "button-first-txt"
                                        }
                                      >
                                        {item.audio.nombre}
                                      </p>
                                      <p
                                        className={
                                          selectedHorario.id == item.id
                                            ? "button-last-txt-selected"
                                            : "button-last-txt"
                                        }
                                      >
                                        {item.hora.substring(0, 5)}
                                      </p>
                                      <p
                                        className={
                                          selectedHorario.id == item.id
                                            ? "button-first-txt-selected"
                                            : "button-first-txt"
                                        }
                                        style={{ fontWeight: '400' }}>
                                        {item.calidad}
                                      </p>
                                      <p
                                        className={
                                          selectedHorario.id == item.id
                                            ? "button-first-txt-selected"
                                            : "button-first-txt"
                                        }
                                        style={{ fontWeight: '400' }}>
                                        {item.sala.nombre}
                                      </p>
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}

                        {selectedHorario.id ? (
                          <>
                            <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px", marginBottom: "10px" }}>Cantidad</h4>
                            <div className="container-main-igresso-column01" style={{ marginLeft: "10px" }}>
                              <ButtonGroup>
                                <Button style={{ backgroundColor: "white", color: "black" }}
                                  onClick={() => {
                                    if (qtdInteiras - 1 > 0) {
                                      setQtdInteiras(qtdInteiras - 1);
                                      handleValuesTotal(qtdInteiras - 1, cupomInfo)
                                    }
                                  }}
                                >
                                  -
                                </Button>
                                <Button disable="true" variant="text" style={{ color: "white" }}>{qtdInteiras}</Button>
                                <Button style={{ backgroundColor: "white", color: "black" }}
                                  onClick={() => {
                                    if (qtdInteiras + 1 <= 10) {
                                      setQtdInteiras(qtdInteiras + 1);
                                      handleValuesTotal(qtdInteiras + 1, cupomInfo)
                                    }
                                  }}
                                >
                                  +
                                </Button>
                              </ButtonGroup>
                              <p
                                style={{ color: "white", fontFamily: "Poppins" }}
                                className="description-igresso"
                              >
                                Butaca tradicional ({qtdInteiras}x) <span style={{ fontWeight: "bold", fontSize: "16px" }}>${" " + parseInt(total).toLocaleString("pt-br", { currency: "CLP" })}
                                </span>
                              </p>
                            </div>
                            <br />

                            <hr style={{ width: "75%", marginBottom: "2%" }} />

                            <h4 style={{ color: "white", paddingLeft: "10px" }}>
                              {" "}
                              Ingresa aquí tu código promocional{" "}
                            </h4>
                            <p style={{ color: "white", paddingLeft: "10px", fontSize: "0.9rem" }}>
                              {" "}
                              Aprovecha tu descuento y ven a disfrutar de
                              CINESOL !{" (No aplica para estrenos)"}
                            </p>
                            <br />
                            <Bx direction="row" gap={"small"} style={{ paddingLeft: "10px" }}>
                              <BootstrapInput
                                id="bootstrap-input-1"
                                autoFocus={true}
                                value={cupom}
                                disabled={filme.estreno == 1 || cupomInfo !== null}
                                placeholder="Digite el código"
                                onChange={handleCupomInfos}
                              />

                              <BootstrapButton
                                variant="contained"
                                onClick={() => inserirCupom()}
                              >
                                Agregar
                              </BootstrapButton>
                            </Bx>

                            <hr style={{ width: "75%", marginTop: "2%" }} />
                            <Button variant="contained" style={{
                              width: '150px',
                              height: '40px',
                              backgroundColor: '#00C455',
                              border: '1px solid #00C455',
                              margin: '20px 0 20px 10px',
                              cursor: 'pointer',
                              fontFamily: 'Poppins',
                              textTransform: 'capitalize'
                            }} endIcon={<NavigateNext />} onClick={handleNavigation}>Continuar</Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </Bx>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
        {/* </div> */}
      </BrowserView>

      <MobileView>
        <ButtonWhatsapp />
        <Box sx={styleboxfilme}>
          <Stepper active={0} />
          <h3 className="title-sinopse" style={{ color: "white", margin: 2, textAlign: 'center' }}>
            {filme.titulo}
          </h3>
          <Box sx={{ width: "85%" }}>
            <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px", marginTop: '4%' }}>
              Seleccione fecha
            </h4>
            <div className="container-DATAS">
              {datas.map((item) => {
                return (
                  <button
                    className={
                      selectedDate.complete == item.complete
                        ? "content-selecione-date-date-Dom-active"
                        : "content-selecione-date-date-Dom"
                    }
                    onClick={() => handleSelectedDate(item)}
                  >
                    <div className="main-content-date-date-Dom">
                      <p
                        className={
                          selectedDate.complete == item.complete
                            ? "first-p-active"
                            : "first-p"
                        }
                      >
                        {item.dayTxt}
                      </p>
                      <p
                        className={
                          selectedDate.complete == item.complete
                            ? "last-p-active"
                            : "last-p"
                        }
                      >
                        {item.dayInt}
                      </p>
                      <p
                        className={
                          selectedDate.complete == item.complete
                            ? "tri-p-active"
                            : "tri-p"
                        }
                      >
                        {item.monthTxt}
                      </p>
                    </div>
                  </button>
                );
              })}
            </div>

            {selectedDate && (
              <>
                <div className="container-seccion-carteira">
                  <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                    Seleccione la función
                  </h4>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 0 10px 0",
                      maxWidth: "100%",
                    }}
                  >
                    {horarios.map((item) => {
                      return (
                        <button
                          className={
                            selectedHorario.id == item.id
                              ? "container-secction-button-selected"
                              : "container-secction-button"
                          }
                          onClick={() => handleSelectedHorario(item)}
                        >
                          <p
                            className={
                              selectedHorario.id == item.id
                                ? "button-first-txt-selected"
                                : "button-first-txt"
                            }
                          >
                            {item.audio.nombre}
                          </p>
                          <p
                            className={
                              selectedHorario.id == item.id
                                ? "button-last-txt-selected"
                                : "button-last-txt"
                            }
                          >
                            {item.hora.substring(0, 5)}
                          </p>
                          <p
                            className={
                              selectedHorario.id == item.id
                                ? "button-first-txt-selected"
                                : "button-first-txt"
                            }
                            style={{ fontWeight: '400' }}>
                            {item.calidad}
                          </p>
                          <p
                            className={
                              selectedHorario.id == item.id
                                ? "button-first-txt-selected"
                                : "button-first-txt"
                            }
                            style={{ fontWeight: '400' }}>
                            {item.sala.nombre}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            {selectedHorario.id ? (
              <>
                <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                  Cantidad
                </h4>
                <div className="container-main-igresso-column01" style={{ marginLeft: '10px', marginBottom: '2%' }}>
                  <ButtonGroup>
                    <Button style={{ backgroundColor: "white", color: "black" }}
                      onClick={() => {
                        if (qtdInteiras - 1 > 0) {
                          setQtdInteiras(qtdInteiras - 1);
                          handleValuesTotal(qtdInteiras - 1, cupomInfo)
                        }
                      }}
                    >
                      -
                    </Button>
                    <Button disable="true" variant="text" style={{ color: "white" }}>{qtdInteiras}</Button>
                    <Button style={{ backgroundColor: "white", color: "black" }}
                      onClick={() => {
                        if (qtdInteiras + 1 <= 10) {
                          setQtdInteiras(qtdInteiras + 1)
                          handleValuesTotal(qtdInteiras + 1, cupomInfo)
                        }
                      }}>
                      +
                    </Button>
                  </ButtonGroup>
                  <p style={{ color: "white", fontFamily: "Poppins" }}>
                    {qtdInteiras}x Butaca Tradicional: <br /> ${" " + parseInt(total).toLocaleString(
                      "pt-br",
                      { currency: "CLP" }
                    )}
                  </p>
                </div>
                <hr style={{ width: "100%", marginBottom: "2%", marginLeft: '10px' }} />

                <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: '10px' }}>
                  Ingresa aquí tu código promocional
                </h4>
                <p style={{ color: "white", fontSize: "0.9rem", marginLeft: '10px', marginBottom: '2%' }}>
                  Aprovecha tu descuento y ven a disfrutar de CINESOL !{" (No aplica para estrenos)"}
                </p>
                <Bx direction="row" style={{ marginLeft: '10px', width: '100%' }}>
                  <BootstrapInput
                    style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                    id="bootstrap-input"
                    value={cupom}
                    disabled={filme.estreno == 1 || cupomInfo !== null}
                    placeholder="Digite el código"
                    onChange={handleCupomInfos}
                  />
                  <BootstrapButton
                    variant="contained"
                    onClick={() => inserirCupom()}
                  >
                    Agregar
                  </BootstrapButton>
                </Bx>

                <hr style={{ width: "100%", marginTop: "4%", marginLeft: '10px' }} />
                <div className="footer-button" style={{ marginLeft: '10px', paddingBottom: "4%" }}>
                  <Button variant="contained" style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#00C455',
                    border: '1px solid #00C455',
                    marginTop: '2%',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize'
                  }} endIcon={<NavigateNext />} onClick={handleNavigation}>Continuar</Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </MobileView>
    </>
  );
};
