import React, { useState } from "react";
import './ButtonWhatsapp.styles.css';
import { FaWhatsapp } from 'react-icons/fa';

export const ButtonWhatsapp = () => {

    const link = "https://api.whatsapp.com/send?phone=56953366416";

    return (
        <a
            href={link}
            className="floating-button"
            target="_blank"
            rel="noopener noreferrer"
        >
            <FaWhatsapp size={30} color="#fff" />
        </a>
    )
}