import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Finally.styles.css";
import { api, apiV2 } from "../../services/api";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import background from "../../assets/background.png";
import { Box as Bx } from "grommet";
import { HeaderSemCarrousel } from "../../components/HomeCompo/HomeCompo";
import Button from "@mui/material/Button";
import { ArrowDownward, ArrowBackIos } from '@material-ui/icons';

export const Finally = () => {

  const ticketUrl = "https://api-cinesol-v2.busticket.cl/api/venta/tickets/"
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const history = useHistory();

  const setResponse = (params) => {
    if (params.get('token_ws') !== undefined) {
      return apiV2.get("transbank/webpay/confirmar-transaccion/" + params.get('token_ws'))
        .then(resp => {
          console.log(resp);
          setData(resp.data);
        })
        .catch(error => {
          console.log(error);
          setData({ motivo: error.response?.data?.msj ?? 'Error contacte a soporte' });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      return Promise.resolve(); // Retorna una promesa vacía si no hay `token_ws`
    }
  };

  useEffect(() => {
    let swalInstance = Swal.fire({
      title: "Verificando Pago:",
      html: "Esperando respuesta de Transbank.",
      allowOutsideClick: false, // Evita que se cierre por clics fuera del modal
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    let params = new URLSearchParams(window.location.search);
  
    setResponse(params).finally(() => {
      swalInstance.close(); // Cierra el Swal cuando la petición finaliza
    });
  }, []);


  return (
    <Box sx={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
        <HeaderSemCarrousel />

        <Box>
          {loading === false ? (
            <div>
              <div style={{ flexWrap: "wrap" }}>
                <div className="container-pai">
                  <div className="container-pai-filme">
                    <div className="informacoes-detalhes">
                      <div className="content-header">
                        <Bx direction="row" align="center">
                          <h4 style={{ color: "white", fontFamily: 'Poppins' }}>
                            Detalle de su compra
                          </h4>
                        </Bx>

                        <Bx direction="row">
                          <div>
                            <div className="description-first">
                              <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Estado: <span style={{ fontWeight: 'normal' }}>{data.motivo}</span>
                              </h4>
                            </div>

                            { data.valor !== undefined && (
                              <>
                                <div className="description-first">
                                  <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                    Valor: <span style={{ fontWeight: 'normal' }}>{data.valor}</span>
                                  </h4>
                                </div>

                                <div className="description-first">
                                  <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                    N° orden de compra: <span style={{ fontWeight: 'normal' }}>{data.buyOrder}</span>
                                  </h4>
                                </div>

                                <div className="description-first">
                                  <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                    Fecha de autorización: <span style={{ fontWeight: 'normal' }}>{data.tdata}</span>
                                  </h4>
                                </div>
                              </>
                            )}
                            

                          </div>
                        </Bx>
                        <div>
                          {data.codigo_auth == 0 && (
                            <a href={ticketUrl + data.vid} target="_blank">
                              <Button variant="contained" style={{
                                width: '200px',
                                height: '40px',
                                backgroundColor: '#00C455',
                                border: '1px solid #00C455',
                                margin: '20px 0 20px 2px',
                                cursor: 'pointer',
                                fontFamily: 'Poppins',
                                textTransform: 'capitalize'
                              }} endIcon={<ArrowDownward />}>Descargar Ticket</Button>
                            </a>
                          )}
                          <Button variant="contained" style={{
                            width: '200px',
                            height: '40px',
                            backgroundColor: '#CCCCCC',
                            color: '#333333',
                            border: '1px solid #CCCCCC',
                            margin: '20px 0 20px 10px',
                            cursor: 'pointer',
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize'
                          }} startIcon={<ArrowBackIos />} onClick={() => history.push("/")}>Ir a cartelera</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <> </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
